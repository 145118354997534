import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Section } from "../../components/Section";
import { Loading } from "../../components/Loading";
import "./style.css";
export const ResetPassword = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleResetPass = async () => {
    setLoading(true);
    //chamar o metodo para a resetar
    setLoading(false);
  };

  return (
    <div className="container">
      {loading ? (
        <Section type="login">
          <Loading size={80}></Loading>
        </Section>
      ) : (
        <Section type="login">
          <div className="row banner-pass-reset-container">
            <div className="col-md-6 pass-reset-background" />
            <div className="col-md-4">
              <div className="pass-reset-container">
                <div className="pass-reset-container-content">
                  <div>
                    <h6 className="pass-reset-title">Mudar a sua password!</h6>

                    <div className="form-group pass-reset-email">
                      <input
                        onChange={(text) => {
                          setEmail(text.target.value);
                        }}
                        type="text"
                        value={email}
                        className="form-control pass-reset-input"
                        placeholder="Insira o seu email"
                      />
                    </div>

                    <div className="pass-reset-button-container">
                      <button
                        disabled={!email}
                        className="btn  pass-reset-bttn"
                        onClick={handleResetPass}
                      >
                        Enviar confirmação
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
      )}
    </div>
  );
};
