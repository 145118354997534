import React from "react";
import { NavBar } from "../NavBar";
import { useLocation } from "react-router-dom";
import "./style.css";

export const ShowHideNavbar = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  console.log("pathh", splitLocation[1]);
  return (
    splitLocation[1] !== "dashboard" ?<NavBar />:null
  );
};
