import PropTypes from "prop-types";
import { forwardRef } from "react";
import "./style.css";
import { DashboardHeader } from "../DashboardHeader";

const Layout = forwardRef(({ children, ...other }, ref) => (
  <div ref={ref} {...other} className="layout">
    <DashboardHeader />
    {children}
  </div>
));

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Layout;
