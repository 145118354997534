import axios from "axios";

axios.defaults.withCredentials = true;

export const signIn = (userName, password) => {
  return axios
    .post("https://api.upra-ies.ao/entrar", {
      userName,
      password,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("token", JSON.stringify(response.data.token));
      }
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const doGetQr = (
  num_processo,
  name,
  sobrenome,
  nome_meio,
  num_cartao,
  b_i,
  activo
) => {
  const estado = activo === "1" ? "ativado" : "desativado";
  const nome = `${name} ${nome_meio} ${sobrenome}`;
  return axios
    .post("https://api.upra-ies.ao/gerar_qrcode", {
      nome,
      num_processo,
      num_cartao,
      b_i,
      estado,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getParentQrCode = (
  parent_id,
  name,
  sobrenome,
  nome_meio,
  num_cartao,
  b_i,
  activo
) => {
  const estado = activo === "1" ? "ativado" : "desativado";
  const nome = `${name} ${nome_meio} ${sobrenome}`;
  return axios
    .post("https://api.upra-ies.ao/gerar_p_qrcode", {
      nome,
      parent_id,
      num_cartao,
      b_i,
      estado,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
};

export const refreshSignIn = () => {
  return axios
    .get("https://api.upra-ies.ao/entrar")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const doClientRegistration = (data) => {
  const {
    num_processo,
    primeiro_nome,
    sobrenome,
    nome_meio,
    data_nascimento,
    genero,
    historico,
    plano,
    num_beneficiarios,
    beneficiarios_activos,
    contacto,
    email,
    num_cartao,
    num_consultas,
    endereco,
    contrato_adesao,
    contrato_adesao_path,
    foto,
    foto_path,
    activo,
    tipo_cliente,
    b_i,
  } = data;
  return axios
    .post(
      "https://api.upra-ies.ao/registro-clientes",
      {
        num_processo,
        primeiro_nome,
        sobrenome,
        nome_meio,
        data_nascimento,
        genero,
        historico,
        plano,
        num_beneficiarios,
        beneficiarios_activos,
        contacto,
        email,
        num_cartao,
        num_consultas,
        rua: endereco.rua,
        bairro: endereco.bairro,
        municipio: endereco.municipio,
        contrato_adesao,
        contrato_adesao_path,
        foto,
        foto_path,
        activo,
        tipo_cliente,
        b_i,
      },
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const doClientUpdate = (data) => {
  const {
    num_processo,
    primeiro_nome,
    sobrenome,
    nome_meio,
    data_nascimento,
    genero,
    historico,
    plano,
    num_beneficiarios,
    beneficiarios_activos,
    contacto,
    email,
    num_cartao,
    num_consultas,
    endereco,
    contrato_adesao,
    contrato_adesao_path,
    foto,
    foto_path,
    activo,
    tipo_cliente,
    b_i,
  } = data;
  return axios
    .put(
      `https://api.upra-ies.ao/registro-clientes/${num_processo}`,
      {
        primeiro_nome,
        sobrenome,
        nome_meio,
        data_nascimento,
        genero,
        historico,
        plano,
        num_beneficiarios,
        beneficiarios_activos,
        contacto,
        email,
        num_cartao,
        num_consultas,
        rua: endereco.rua,
        bairro: endereco.bairro,
        municipio: endereco.municipio,
        contrato_adesao,
        contrato_adesao_path,
        foto,
        foto_path,
        activo,
        tipo_cliente,
        b_i,
      },
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const doClientSearchByname = (primeiro_nome, sobrenome) => {
  return axios
    .post(
      "https://api.upra-ies.ao/procurar-registro-nome",
      {
        primeiro_nome,
        sobrenome,
      },
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const doClientSearchByProcess = (num_processo) => {
  return axios
    .post(
      "https://api.upra-ies.ao/procurar-registro-processo",
      {
        num_processo,
      },
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const doClientSearchByBi = (numBi) => {
  return axios
    .post(
      "https://api.upra-ies.ao/procurar-registro-bi",
      {
        numBi,
      },
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const doParentRegistration = (data) => {
  const {
    parent_id,
    primeiro_nome,
    sobrenome,
    nome_meio,
    data_nascimento,
    genero,
    historico,
    contacto,
    email,
    num_cartao,
    num_consultas,
    activo,
    num_processo,
    b_i,
  } = data;
  return axios
    .post(
      "https://api.upra-ies.ao/registro-parentes",
      {
        parent_id,
        primeiro_nome,
        sobrenome,
        nome_meio,
        data_nascimento,
        genero,
        historico,
        contacto,
        email,
        num_cartao,
        num_consultas,
        activo,
        num_processo,
        b_i,
      },
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const doUserRegistration = (data) => {
  const { nome, contacto, email, password, role, data_criacao, activo } = data;
  return axios
    .post(
      "https://api.upra-ies.ao/registrar-usuario-gestor",
      {
        nome,
        contacto,
        email,
        password,
        role,
        data_criacao,
        activo,
      },
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const doGetMunicipios = () => {
  return axios
    .post("https://api.upra-ies.ao/get-municipios")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getAvailable = (num_processo) => {
  return axios
    .post(
      "https://api.upra-ies.ao/vaga-clientes",
      {
        num_processo,
      },
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const doUploadImage = (file, numProcesso) => {
  if (!file.type.startsWith("image/")) {
    return Promise.reject("Apenas imagens são aceites (.jpg, .jpeg, .png).");
  }
  const customName = numProcesso;
  const fd = new FormData();
  fd.append(file.type.split("/")[0], file, customName);
  return axios
    .post("https://api.upra-ies.ao/upload-images", fd)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const doUploadFiles = (file, numProcesso) => {
  if (!file.type.startsWith("application/")) {
    return Promise.reject("Apenas ficheiros .pdf");
  }
  const customName = numProcesso;
  const fd = new FormData();
  fd.append(file.type.split("/")[0], file, customName);
  return axios
    .post("https://api.upra-ies.ao/upload-files", fd)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
