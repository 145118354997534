import React from "react";

import "./style.css";
import { Container } from "react-bootstrap";

import "./style.css";

export const Section = ({ children, type }) => {
  return (
    <Container
      className={
        type === "white"
          ? "section-white"
          : type === "login"
          ? "login-section"
          : type === "contact"
          ? "contact-section"
          : "section"
      }
    >
      {children}
    </Container>
  );
};
