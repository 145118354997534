import React from "react";

import "./style.css";

export const NotificationModal = ({ handleClose, show, buttonText, items }) => {
  const firstFive = items.slice(0, 5);
  const showHideClassName = show
    ? "notification-modal notification-display-block"
    : "notification-modal notification-display-none";

  return (
    <div className={showHideClassName}>
      <section className="notification-modal-container container">
        <h3 className="notification-title">Notificações</h3>
        {firstFive.map((item, i) => {
          return (
            <div className="notification-modal-content">
              <p className="notification-modal-user-text">{item.user}</p>
              <p className="notification-modal-message">{item.message}</p>
            </div>
          );
        })}
        <button className="btn notification-btn" onClick={handleClose}>
          {buttonText}
        </button>
      </section>
    </div>
  );
};
