import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./style.css";

export const Dropdown = ({ items }) => {
  const [click, setClick] = useState(false);

  const handleClick = () => {
    setClick(!click);
  };
  return (
    <>
      <ul
        onClick={handleClick}
        className={click ? "new-dropdown-menu clicked" : "new-dropdown-menu"}
      >
        {items.map((item, index) => {
          return (
            <li key={index}>
              {item.status && (
                <p className="dropdown-status-label">
                  Status:
                  <span className="dropdown-status-text">{item.status}</span>
                </p>
              )}
              <Link
                to={item.path}
                className={item.cName}
                onClick={() => setClick(false)}
              >
                {item.title}
              </Link>
              {index < items.length - 1 && (
                <hr className="dropdown-separator" />
              )}
            </li>
          );
        })}
      </ul>
    </>
  );
};
