import card1 from "../images/card1.png";
import card2 from "../images/card2.png";
import card3 from "../images/card3.png";
import card4 from "../images/card4.png";

export const SliderData = [
  {
    image: card1,
    title: "Plano Bronze",
    consultas: "Consultas de Urgência 30%",
    atos_medicos: "20% de desconto em todos os actios médicos",
    farmacia: "10% desconto insumos, materiais e medicamentos (farmácia)",
    inclusao:
      "Inclusão nos programas de atenção a saúde (diabetes,hipertensão e controlo de peso)",
    beneficiarios: 4,
  },
  {
    image: card2,
    title: "Plano Prata",
    consultas: "Consultas de Urgência 30%",
    atos_medicos: "20% de desconto em todos os actios médicos",
    farmacia: "10% desconto insumos, materiais e medicamentos (farmácia)",
    inclusao:
      "Inclusão nos programas de atenção a saúde (diabetes,hipertensão e controlo de peso)",
    beneficiarios: 6,
  },
  {
    image: card3,
    title: "Plano Ouro",
    consultas: "Consultas de Urgência 30%",
    atos_medicos: "20% de desconto em todos os actios médicos",
    farmacia: "10% desconto insumos, materiais e medicamentos (farmácia)",
    inclusao:
      "Inclusão nos programas de atenção a saúde (diabetes,hipertensão e controlo de peso)",
    beneficiarios: 8,
  },
  {
    image: card4,
    title: "Plano Platina",
    consultas: "Consultas de Urgência 30%",
    atos_medicos: "20% de desconto em todos os actios médicos",
    farmacia: "10% desconto insumos, materiais e medicamentos (farmácia)",
    inclusao:
      "Inclusão nos programas de atenção a saúde (diabetes,hipertensão e controlo de peso)",
    beneficiarios: 10,
  },
];
