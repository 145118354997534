import React, { useState, useEffect } from "react";
import "./style.css";
import { Avatar } from "../Avatar";
import picIncon from "../../images/tavatar.jpg";
import { useHistory } from "react-router-dom";
import {
  doClientSearchByname,
  doClientSearchByProcess,
  doClientSearchByBi,
} from "../../services/Conection";

export const SearchEdit = () => {
  const [typeSearch, setTypeSearch] = useState("");
  const [resultOrder, setResutlOrder] = useState("");
  const [searchValue, setSearchValue] = useState("");
  /*sEClientData-> é carregado com a data do cliente procurado*/
  const [sEClientData, setSEClientData] = useState();

  /*returnedClients-> é carregado com os clientes retornados da pesquisa*/
  const [returnedClients, setReturnedClients] = useState([""]);

  const history = useHistory();
  const [primeiro_nome, setPrimeiroNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [num_processo, setNumProcesso] = useState("");
  const [num_bi, setNumBi] = useState("");

  useEffect(() => {
    setTypeSearch("");
    setResutlOrder("");
    setSearchValue("");
    setSEClientData("");
    setReturnedClients([""]);
  }, []);

  const compare = (a, b) => {
    if (a.primeiro_nome < b.primeiro_nome) {
      return -1;
    }
    if (a.primeiro_nome > b.primeiro_nome) {
      return 1;
    }
    return 0;
  };

  const countClients = (type) => {
    let count = 0;
    for (let i = 0; i < returnedClients.length; i++) {
      if (returnedClients[i].type === type) {
        count = count + 1;
      }
    }
    return count;
  };

  const handleSearch = async () => {
    if (typeSearch === "pnome") {
      if (primeiro_nome !== "" && sobrenome !== "") {
        const result = await doClientSearchByname(primeiro_nome, sobrenome);
        setClientData(result.user);
      }
    }
    if (typeSearch === "pnp") {
      if (num_processo !== "") {
        const result = await doClientSearchByProcess(num_processo);
        setClientData(result.user);
      }
    }

    if (typeSearch === "pbi") {
      if (num_bi !== "") {
        const result = await doClientSearchByBi(num_bi);
        setClientData(result.user);
      }
    }
  };

  useEffect(() => {
    if (typeSearch === "pnome") {
    }

    if (typeSearch === "pnumero") {
      /* fazer o get de busca por telefone */
    }

    if (typeSearch === "pbi") {
      /* fazer o get de busca por bi */
    }

    if (typeSearch === "") {
      setReturnedClients([]);
      setSearchValue("");
    }
  }, [searchValue, typeSearch]);

  useEffect(() => {
    if (resultOrder === "oalfabetica") {
      setReturnedClients(returnedClients.sort(compare));
    }
  }, [resultOrder]);

  const setClientData = (value) => {
    setSEClientData({
      num_processo: value.num_processo,
      primeiro_nome: value.primeiro_nome,
      ultimo_nome: value.sobrenome,
      nome_meio: value.nome_meio,
      phone: value.contacto,
      identification: value.b_i,
      photo: picIncon,
      type: value.tipo_cliente,
      plano: value.plano,
      num_cartao: value.num_cartao,
    });
  };
  const editClient = (numProcesso) => {
    console.log("abir editar usuario ", numProcesso);
    history.push(`/dashboard/cadastrar-novo-cliente/:${numProcesso}`, {
      from: "procurar-editar",
      params: numProcesso,
    });
  };

  return (
    <div className="container">
      <div className="row  search-edit-header-container">
        <div className="col-md-2 search-edit-header-title">Clientes</div>
        <div className="col-md-4">
          <select
            className="form-control  search-edit-header-select"
            onChange={(text) => setTypeSearch(text.target.value)}
          >
            <option value="">Escolher tipo de Pesquisa</option>
            <option value="pnome">Pesquisar por nome</option>
            <option value="pnp">Pesquisar por Nº do Processo</option>
            <option value="pbi">Pesquisar por Nº do B.I</option>
          </select>
        </div>
        {/** Pesqquisa por nome */}
        <div
          className={
            typeSearch === "pnome" ? "row" : "search-edit-header-hidden"
          }
        >
          <div className="col-md-6 col-md-offset-3">
            <input
              placeholder="Insira o primeiro nome"
              id="primeiro-nome"
              type="text"
              className="form-control client-register-input"
              value={primeiro_nome}
              onChange={(text) => setPrimeiroNome(text.target.value)}
            />
          </div>
          <div className="col-md-6 col-md-offset-3">
            <input
              placeholder="Insira o sobrenome"
              id="sobrenome"
              type="text"
              className="form-control client-register-input"
              value={sobrenome}
              onChange={(text) => setSobrenome(text.target.value)}
            />
          </div>
        </div>
        {/**Pesquisa por processo */}
        <div
          className={typeSearch === "pnp" ? "row" : "search-edit-header-hidden"}
        >
          <div className="col-md-12 col-md-offset-3">
            <input
              placeholder="Insira o número do processo"
              id="num-processo"
              type="number"
              className="form-control client-register-input"
              value={num_processo}
              onChange={(text) => setNumProcesso(parseInt(text.target.value))}
            />
          </div>
        </div>
        {/**Pesquisa por bi */}
        <div
          className={typeSearch === "pbi" ? "row" : "search-edit-header-hidden"}
        >
          <div className="col-md-12 col-md-offset-3">
            <input
              placeholder="Insira o número do bi"
              id="bi"
              type="text"
              className="form-control client-register-input"
              value={num_bi}
              onChange={(text) => setNumBi(text.target.value)}
            />
          </div>
        </div>

        {}
        <div
          className={
            typeSearch !== ""
              ? "col-md-4 search-edit-button-container"
              : "search-edit-header-hidden"
          }
        >
          <button className="search-edit-button" onClick={handleSearch}>
            Pesquisar
          </button>
        </div>
      </div>
      <div className="search-edit-content-container">
        {/* <div className="row search-edit-content-header">
          <div className="col-md-2" />
          <div className="col-md-10">
            <select
              className="search-edit-content-select"
              onChange={(text) => setResutlOrder(text.target.value)}
            >
              <option value="">Ordenar Por</option>
              <option value="oalfabetica">Ordem Alfabetica</option>
            </select>
          </div>
        </div> */}
        <div className="row search-edit-content-result">
          <div className="col-md-2 search-edit-content-result-resume">
            {/* total de clientes */}
            <div className="row">
              <div className="col-md-10">
                <p className="search-edit-content-result-clients-text1">
                  Total clientes
                </p>
              </div>
              <div className="col-md-2">
                <p className="search-edit-content-result-clients-text2">
                  {returnedClients.length}
                </p>
              </div>
            </div>
            {/* estudantes */}
            <div className="row">
              <div className="col-md-10">
                <p className="search-edit-content-result-clients-text1">
                  Estudantes
                </p>
              </div>
              <div className="col-md-2">
                <p className="search-edit-content-result-clients-text2">
                  {countClients("UPRA")}
                </p>
              </div>
            </div>
            {/* familiares */}
            <div className="row">
              <div className="col-md-10">
                <p className="search-edit-content-result-clients-text1">
                  Familiares
                </p>
              </div>
              <div className="col-md-2">
                <p className="search-edit-content-result-clients-text2">
                  {countClients("family")}
                </p>
              </div>
            </div>
            {/* ex extudante */}
            <div className="row">
              <div className="col-md-10">
                <p className="search-edit-content-result-clients-text1">
                  Ex estudantes
                </p>
              </div>
              <div className="col-md-2">
                <p className="search-edit-content-result-clients-text2">
                  {countClients("ex-upra")}
                </p>
              </div>
            </div>

            <hr className="search-edit-content-result-space"></hr>
            <p>Tag</p>
            <p>
              <span className="search-edit-content-result-b-ball">b</span>{" "}
              <span>Estudante</span>
            </p>
            <p>
              <span className="search-edit-content-result-g-ball">b</span>{" "}
              <span>Ex estudante</span>
            </p>
            <p>
              <span className="search-edit-content-result-r-ball">b</span>{" "}
              <span>Familiar</span>
            </p>
          </div>
          <div className="col-md-4 search-edit-content-result-avatar">
            {/* {returnedClients &&
              returnedClients.map((value, index) => {
                return (
                  <div
                    className="row g-0 search-edit-content-result-box"
                    key={index}
                    onClick={() => setClientData(value)}
                  >
                    <div className="col-md-1 search-edit-content-result-box-content-lines">
                      <div
                        className={
                          value.type === "UPRA"
                            ? "search-edit-content-result-bline"
                            : value.type === "ex-upra"
                            ? "search-edit-content-result-gline"
                            : value.type === "family"
                            ? "search-edit-content-result-rline"
                            : null
                        }
                      />
                    </div>
                    <div className="col-md-11 search-edit-content-result-avatar-content">
                      {
                        <Avatar
                          img={value.photo}
                          nome={`${value.primeiro_nome} ${value.ultimo_nome}`}
                          numero={value.phone}
                          ident={value.identification}
                        ></Avatar>
                      }
                    </div>
                  </div>
                );
              })}
          </div> */}
            {sEClientData && (
              <div
                className="row g-0 search-edit-content-result-box"
                // onClick={() => setClientData(value)}
              >
                <div className="col-md-1 search-edit-content-result-box-content-lines">
                  <div
                    className={
                      sEClientData.type === "UPRA"
                        ? "search-edit-content-result-bline"
                        : sEClientData.type === "ex-upra"
                        ? "search-edit-content-result-gline"
                        : sEClientData.type === "family"
                        ? "search-edit-content-result-rline"
                        : null
                    }
                  />
                </div>
                <div className="col-md-11 search-edit-content-result-avatar-content">
                  {
                    <Avatar
                      img={sEClientData.photo}
                      nome={`${sEClientData.primeiro_nome} ${sEClientData.ultimo_nome}`}
                      numero={sEClientData.phone}
                      ident={sEClientData.identification}
                    ></Avatar>
                  }
                </div>
              </div>
            )}
          </div>
          {sEClientData && (
            <div className="col-md-6 search-edit-content-result-click">
              <div className="row">
                <div className="col-md-6">
                  {
                    <Avatar
                      img={sEClientData.photo}
                      nome={`${sEClientData.primeiro_nome} ${sEClientData.ultimo_nome}`}
                      numero={sEClientData.phone}
                      ident={sEClientData.identification}
                    ></Avatar>
                  }
                </div>
                <div className="col-md-3">
                  <button
                    onClick={() => editClient(sEClientData.num_processo)}
                    className="search-edit-content-result-click-button"
                  >
                    Editar Cliente
                  </button>
                </div>
                <div className="col-md-3">
                  <button
                    className="search-edit-content-result-click-button"
                    onClick={() => window.print()}
                  >
                    Imprimir
                  </button>
                </div>
              </div>
              <div>
                <hr></hr>
                <p className="search-edit-content-result-click-text">{`Nome Completo: ${sEClientData.primeiro_nome} ${sEClientData.nome_meio} ${sEClientData.ultimo_nome}`}</p>
                <p className="search-edit-content-result-click-text">{`Nº Processo: ${sEClientData.num_processo}`}</p>
                <p className="search-edit-content-result-click-text">{`Nº B.I: ${sEClientData.identification}`}</p>
                <p className="search-edit-content-result-click-text">{`Nº Telefone: ${sEClientData.phone}`}</p>
                <p className="search-edit-content-result-click-text">{`Plano: ${sEClientData.plano}`}</p>
                <p className="search-edit-content-result-click-text">{`Nº do Cartão: ${sEClientData.num_cartao}`}</p>
                <p className="search-edit-content-result-click-text">{`Tag: ${sEClientData.type}`}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
