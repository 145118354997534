import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "./style.css";
import { doUserRegistration } from "../../services/Conection";
import { ModalBackoffice } from "../ModalBackoffice";
import modalIcon from "../../images/modal_icon.png";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export const SystemUserRegisterForm = () => {
  const history = useHistory();
  const user = useSelector((state) => state.user.value);
  const { role } = user;
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [startDate, setStartDate] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [userData, setUserData] = useState({
    nome: "",
    contacto: "",
    email: "",
    password: "",
    confirm_password: "",
    role: "",
    data_criacao: "",
    activo: "",
  });
  const [submitted, setSubmitted] = useState("");
  const [modalIsVisible, setModalIsVisible] = useState(false);

  const handleRegistration = async () => {
    if (emptyFields()) {
      setErrorMessage("Erro: Existem campos por preencher");
      setError(!error);
    } else {
      const result = await doUserRegistration(userData);
      if (result.id) {
        handleClean();
        setModalIsVisible(!modalIsVisible);
      } else {
        setErrorMessage(
          "Erro: Não é possivel gravar úsuarios com o mesmo nome"
        );
        setError(!error);
      }
    }
  };

  useEffect(() => {
    if (!user.token) {
      history.push("/entrar", { from: "cadastrar-novo-usuario" });
    }
  });

  const handleClean = () => {
    setUserData({
      nome: "",
      contacto: "",
      email: "",
      password: "",
      confirm_password: "",
      role: " ",
      data_criacao: "",
      activo: "",
    });
    setStartDate();
  };

  const handleModal = () => {
    setModalIsVisible(!modalIsVisible);
  };

  const emptyFields = () => {
    if (
      userData.nome === "" ||
      userData.contacto === "" ||
      userData.email === "" ||
      userData.password === "" ||
      userData.confirm_password === "" ||
      userData.role === "" ||
      userData.data_criacao === "" ||
      userData.activo === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    handleClean();
  }, []);

  return (
    <div className="form-container container">
      <ModalBackoffice
        show={modalIsVisible}
        handleClose={handleModal}
        buttonText="ok"
      >
        <img src={modalIcon} alt="imagem do modal"></img>
        <p>Cadastro realizado com sucesso</p>
      </ModalBackoffice>
      {error && <h1>{errorMessage}</h1>}
      <h2 className="user-registration-title">Novo Usuário do sistema</h2>
      <div name="form">
        {/*nome & email */}
        <div className="row user-registration-header-container">
          {/*nome*/}
          <div className="col-md-6 col-md-offset-3">
            <div
              className={
                "form-group user-registration-form-group" +
                (submitted && !userData.nome
                  ? " user-registration-has-error"
                  : "")
              }
            >
              <input
                placeholder="Nome de usuário"
                id="nome"
                type="text"
                className="form-control user-registration-input"
                value={userData.nome}
                onChange={(text) =>
                  setUserData({
                    ...userData,
                    nome: text.target.value,
                  })
                }
              />
              {submitted && !userData.nome && (
                <div className="user-registration-help-block">
                  Campo nome de usuário é necessário
                </div>
              )}
            </div>
          </div>
          {/*email*/}
          <div className="col-md-6 col-md-offset-3">
            <div
              className={
                "form-group user-registration-form-group" +
                (submitted && !userData.email
                  ? " register-client-has-error"
                  : "")
              }
            >
              <input
                id="email"
                placeholder="meunome@exemplo.co"
                type="text"
                className="form-control user-registration-input"
                value={userData.email}
                onChange={(text) =>
                  setUserData({
                    ...userData,
                    email: text.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>

        {/*contacto e password*/}
        <div className="row user-registration-contact-container">
          {/*contacto*/}
          <div className="col-md-4 col-md-offset-3">
            <div
              className={
                "form-group user-registration-form-group" +
                (submitted && !userData.contacto
                  ? " register-client-has-error"
                  : "")
              }
            >
              <input
                id="contacto"
                type="tel"
                pattern="[0-9]{3}[0-9]{2}[0-9]{2}[0-9]{2}"
                placeholder="Contacto"
                className="form-control user-registration-input"
                value={userData.contacto}
                onChange={(text) =>
                  setUserData({
                    ...userData,
                    contacto: text.target.value,
                  })
                }
              />
              {submitted && !userData.contacto && (
                <div className="user-registration-help-block">
                  Campo contacto é necessário
                </div>
              )}
            </div>
          </div>
          {/*password*/}
          <div className="col-md-4 col-md-offset-3">
            <div
              className={
                "form-group user-registration-form-group" +
                (submitted && !userData.password
                  ? " user-registration-has-error"
                  : "")
              }
            >
              <input
                id="password"
                placeholder="Introduza a palavra passe"
                type={isVisible ? "text" : "password"}
                className="form-control user-registration-input"
                value={userData.password}
                onChange={(text) =>
                  setUserData({
                    ...userData,
                    password: text.target.value,
                  })
                }
              />
            </div>

            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  onChange={() => setIsVisible(!isVisible)}
                  className="custom-control-input"
                  id="mostrar"
                />
                <label
                  className="user-registration-label user-registration-login-check"
                  htmlFor="mostrar"
                >
                  Mostrar senha
                </label>
              </div>
            </div>
          </div>
          {/*confirm password*/}
          <div className="col-md-4 col-md-offset-3">
            <div
              className={
                "form-group user-registration-form-group" +
                (submitted && !userData.confirm_password
                  ? " user-registration-has-error"
                  : "")
              }
            >
              <input
                id="c-password"
                placeholder="Introduza novamente a palavra passe"
                type={isVisible ? "text" : "password"}
                className="form-control user-registration-input"
                value={userData.confirm_password}
                onChange={(text) =>
                  setUserData({
                    ...userData,
                    confirm_password: text.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>

        {/*role, estado e data de criação*/}
        <div className="row user-registration-role-container">
          {/*role */}
          <div className="col-md-4 col-md-offset-3">
            <div
              className={
                "form-group user-registration-form-group" +
                (submitted && !userData.role ? " has-error" : "")
              }
            >
              <select
                className="form-control  form-select user-registration-select"
                value={userData.role}
                onChange={(text) =>
                  setUserData({
                    ...userData,
                    role: text.target.value,
                  })
                }
              >
                <option value="">Favor selecionar a função</option>
                <option value="gestor">Gestor</option>
                <option value="parceiro">Parceiro</option>
                {role === "adm" && <option value="adm">Administrador</option>}
              </select>

              {submitted && !userData.role && (
                <div className="user-registration-help-block">
                  Campo Função é necessário
                </div>
              )}
            </div>
          </div>

          {/*data de criação*/}
          <div className="col-md-4 col-md-offset-3">
            <div
              className={
                "form-group user-registration-form-group" +
                (submitted && !userData.data_criacao ? " has-error" : "")
              }
            >
              <DatePicker
                className="user-registration-input"
                placeholderText="Selecionar a data de hoje"
                selected={startDate}
                dateFormat="MM/dd/yyyy"
                onChange={(date) => {
                  setStartDate(date);
                  setUserData({
                    ...userData,
                    data_criacao: moment(startDate).format("YYYY-MM-DD"),
                  });
                }}
              />
              {submitted && !userData.data_criacao && (
                <div className="user-registration-help-block">
                  Campo data de criação é necessário
                </div>
              )}
            </div>
          </div>

          {/*estado*/}
          <div className="col-md-4 col-md-offset-3">
            <div
              className={
                "form-group user-registration-form-group" +
                (submitted && !userData.activo ? " has-error" : "")
              }
            >
              <select
                className="form-control  form-select user-registration-select"
                value={userData.activo}
                onChange={(text) =>
                  setUserData({
                    ...userData,
                    activo: parseInt(text.target.value),
                  })
                }
              >
                <option value="">Favor selecionar o Estado</option>
                <option value="1">Ativo</option>
                <option value="0">Desativado</option>
              </select>

              {submitted && !userData.activo && (
                <div className="user-registration-help-block">
                  Campo estado é necessário
                </div>
              )}
            </div>
          </div>
        </div>
        {/*botao*/}
        <div className="row user-registration-buttons-container">
          <div className="col-md-6">
            <button
              disabled={emptyFields()}
              className="btn user-registration-btn"
              onClick={() => {
                setSubmitted(true);
                handleRegistration();
              }}
            >
              Registrar
            </button>
          </div>
          <div className="col-md-6">
            <button className="btn user-registration-btn" onClick={handleClean}>
              Limpar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
