import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    value: { userName: "", role: "", activo: "", token: "", auth: "" },
  },
  reducers: {
    login: (state, action) => {
      state.value = action.payload;
    },
    logout: (state) => {
      state.value = { userName: "", role: "", activo: "", token: "", auth: "" };
    },

    addUser: (state, action) => {
      state.value = action.payload;
    },

    deleteUser: (state, action) => {
      return state.value;
    },
    updateUser: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { login, logout, addUser, deleteUser, updateUser } =
  userSlice.actions;

export default userSlice.reducer;
