import React from "react";
import { Home } from "../../pages/Home";
import { Contact } from "../../pages/Contact";
import { Login } from "../../pages/Login";
import { ResetPassword } from "../../pages/ResetPassword";
import { Dashboard } from "../../pages/Dashboard";
import { DashboardSearchEditClient } from "../../pages/DashboardSearchEditClient";
import { DashboardClientRegistration } from "../../pages/DashboardClientRegistration";
import { DashboardBackOfficeUserRegistration } from "../../pages/DashboardBackOfficeUserRegistration";
import { DashboarParentRegistration } from "../../pages/DashboardParentRegistration";
import { Route, Switch } from "react-router-dom";
import Logout from "../Logout";

export const RenderRoutes = ({ routes }) => {
  return (
    <Switch>
      {routes.map((route, i) => {
        return <RouteWithSubRoutes key={route.key} {...route} />;
      })}
      <Route component={() => <h1>Pagina não encontrada</h1>} />
    </Switch>
  );
};

export const ROUTES = [
  { path: "/", key: "ROOT", exact: true, component: Home },
  {
    path: "/entrar",
    key: "LOGIN_ROOT",
    component: RenderRoutes,
    routes: [
      { path: "/entrar", key: "LOGIN", exact: true, component: Login },
      {
        path: "/entrar/resetar-pass",
        key: "PASSWORD_RESET",
        exact: true,
        component: ResetPassword,
      },
    ],
  },

  { path: "/contato", key: "CONTACTO", exact: true, component: Contact },
  {
    path: "/dashboard",
    key: "DASHBOARD",
    component: RenderRoutes,
    routes: [
      {
        path: "/dashboard",
        key: "DASHBOARD_ROOT",
        exact: true,
        component: Dashboard,
      },
      {
        path: "/dashboard/procurar-editar",
        key: "SEARCH",
        exact: true,
        component: DashboardSearchEditClient,
      },
      {
        path: "/dashboard/cadastrar-novo-cliente",
        key: "NEWCLIENT",
        exact: true,
        component: DashboardClientRegistration,
      },
      {
        path: "/dashboard/cadastrar-novo-parente",
        key: "NEWPARENT",
        exact: true,
        component: DashboarParentRegistration,
      },
      {
        path: "/dashboard/cadastrar-novo-usuario",
        key: "NEWUSER",
        exact: true,
        component: DashboardBackOfficeUserRegistration,
      },
      {
        path: "/dashboard/cadastrar-novo-cliente/:id",
        key: "EDITCLIENT",
        exact: true,
        component: DashboardClientRegistration,
      },

      {
        path: "/dashboard/logout",
        key: "LOGOUT",
        exact: true,
        component: Logout,
      },
    ],
  },
];

export const RouteWithSubRoutes = (route) => {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
};
