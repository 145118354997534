import React, { useEffect } from "react";

import "./style.css";
import { SideBar } from "../../components/SiderBar";
import Layout from "../../components/Layout";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../features/user";

export const Dashboard = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const history = useHistory();

  useEffect(() => {
    const localState = JSON.parse(localStorage.getItem("user"));
    if (localState !== null) {
      dispatch(
        login({
          userName: localState.userName,
          role: localState.role,
          activo: localState.activo,
          token: localState.token,
          auth: localState.auth,
        })
      );
    }
    if (!user.token) {
      history.push("/entrar", { from: "dashboard" });
    }
  }, []);

  return (
    <div className="row g-0">
      <div className="col-md-2">
        <SideBar></SideBar>
      </div>
      <div className="col-md-10">
        <Layout></Layout>
      </div>
    </div>
  );
};
