import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import "./style.css";

export const Loading = ({ size }) => {
  return (
    <div>
      <AiOutlineLoading3Quarters
        size={size}
        className="loading-spinner"
        color="#3dbf75"
      />
    </div>
  );
};
