import React, { useState } from "react";

import "./style.css";
import { Section } from "../Section";

import "./style.css";

export const Footer = () => {
  const [email, setEmail] = useState("");

  return (
    <div className="footer">
      <Section type="white">
        <div className="row content-container">
          <div className="col-md-4">
            <p className="footer-text">Bairro Talatona, Via AL-16/Luanda</p>
            <i className="fas fa-envelope fa-1x footer-email-container">
              <span className="footer-email footer-text">
                geral@familiaupra.ao
              </span>
            </i>
            <br />
            <i className="fas fa-phone fa-1x ">
              <span className="footer-email footer-text">+244 947 632 682</span>
            </i>

            <div className="col-md-4 social-media-container">
              <div className="row">
                <div className="col-md-6">
                  <i className="fab fa-facebook fa-2x icon"></i>
                </div>
                <div className="col-md-6">
                  <i className="fab fa-instagram fa-2x icon"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 ">
            <p className="footer-text-title">Links úteis</p>
            <p>
              <a className="footer-links-u" href="/sobre">
                Sobre nós
              </a>
            </p>
            <p>
              <a className="footer-links-u" href="/terms">
                Termos e condições
              </a>
            </p>
            <p>
              <a className="footer-links-u" href="/privacidade">
                Politica de privacidade
              </a>
            </p>
          </div>
          <div className="col-md-4 ">
            <p className="footer-text-title">Subscreva</p>
            <p className="footer-text">
              Subcreva para receber dicas e artigos todas <br /> as semanas
            </p>
            <div>
              <input
                id="email"
                className="footer-subscribe-email-input"
                placeholder="Insira seu email"
                type="email"
                value={email}
                onChange={(text) => setEmail(text.target.value)}
              />
            </div>
            <button className="footer-subscribe-button" disabled={!email}>
              Subscrever
            </button>
          </div>
        </div>
      </Section>
    </div>
  );
};
