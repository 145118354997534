import React, { useState } from "react";
import "./style.css";
import { IoIosArrowDown } from "react-icons/io";
import { Dropdown } from "../Dropdown";

export const Avatar = ({ type, nome, numero, ident, img }) => {
  const userItems = [
    { status: "Online", cName: "dropdown-link" },
    { title: "Pedir ajuda", path: "ajuda", cName: "dropdown-link" },
    { title: "Sair", path: "dashboard/logout", cName: "dropdown-link" },
  ];

  const [isVisible, setIsVIsible] = useState(false);

  const showDropdown = () => {
    setIsVIsible(!isVisible);
  };

  return (
    <>
      {type === "normal" ? (
        <div>
          <img src={img} alt="avatar" className="avatar" />
        </div>
      ) : type === "header" ? (
        <div className="row g-0 header-avatar">
          <div className="col-md-8">
            <p className="avatar-text1">{nome}</p>
          </div>
          <div className="col-md-2">
            <button className="header-avatar-btn" onClick={showDropdown}>
              <IoIosArrowDown />
              {isVisible && <Dropdown items={userItems} />}
            </button>
          </div>
          <div className="col-md-2 avatar-container">
            <img src={img} alt="avatar" className="avatar" />
          </div>
        </div>
      ) : (
        <div className="row avatar-text-container g-0">
          <div className="col-md-2 avatar-container">
            <img src={img} alt="avatar" className="avatar" />
          </div>
          <div className="col-md-10">
            <p className="avatar-text1">{nome}</p>
            {numero && <p className="avatar-text2">{`${numero} | ${ident}`}</p>}
          </div>
        </div>
      )}
    </>
  );
};
