import React from "react";

import "./style.css";
import { SideBar } from "../../components/SiderBar";
import Layout from "../../components/Layout";
import { SearchEdit } from "../../components/SearchEdit";

export const DashboardSearchEditClient = () => {
  return (
    <div className="row g-0">
      <div className="col-md-2">
        <SideBar></SideBar>
      </div>
      <div className="col-md-10">
        <Layout>
          <SearchEdit />
        </Layout>
      </div>
    </div>
  );
};
