import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import "./style.css";
import {
  doParentRegistration,
  getParentQrCode,
  getAvailable,
} from "../../services/Conection";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ModalBackoffice } from "../ModalBackoffice";
import modalIcon from "../../images/modal_icon.png";
export const ParentRegisterForm = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);
  const [qrButtonEnable, setQrButtonEnable] = useState(false);
  const [startDate, setStartDate] = useState();
  const [qrErrorVisible, setQrErrorVisible] = useState(false);
  const [qrCodeMessage, setqrCodeMessage] = useState("");
  const [full, setfull] = useState(true);
  const [clientData, setClientData] = useState({
    num_processo: "",
    primeiro_nome: "",
    sobrenome: "",
    nome_meio: "",
    data_nascimento: "",
    genero: "",
    b_i: "",
    historico: "",
    contacto: "",
    email: "",
    num_cartao: "",
    activo: "",
    num_consultas: "",
    parent_id: "",
  });
  const history = useHistory();
  const user = useSelector((state) => state.user.value);
  const [modalIsVisible, setModalIsVisible] = useState(false);

  const handleModal = () => {
    setModalIsVisible(!modalIsVisible);
  };

  const handleRegistration = async () => {
    const result = await doParentRegistration(clientData);
    if (result.err || result === undefined) {
      setErrorMessage("Erro: Não é possivel gravar úsuarios com o mesmo nome");
      setErrorVisible(!errorVisible);
    }
    if (result.parent.id || result.status === 200) {
      handleClean();
      setqrCodeMessage("");
      setQrErrorVisible(!qrErrorVisible);
      setModalIsVisible(!modalIsVisible);
    }
  };

  useEffect(() => {
    if (!user.token) {
      history.push("/entrar", { from: "cadastrar-novo-parente" });
    }
  });

  const handleClean = () => {
    setClientData({
      num_processo: "",
      primeiro_nome: "",
      sobrenome: "",
      nome_meio: "",
      data_nascimento: "",
      genero: "",
      b_i: "",
      historico: "",
      contacto: "",
      email: "",
      num_cartao: "",
      activo: "",
      num_consultas: "",
      parent_id: "",
    });
    setStartDate();
    setfull(true);
  };

  const updateCardNumber = () => {
    setClientData({
      ...clientData,
      num_cartao: clientData.parent_id,
    });
  };

  useEffect(() => {
    if (clientData.parent_id) {
      updateCardNumber();
    }
  }, [clientData.parent_id]);

  console.log("data", clientData);

  const doSubmission = () => {
    const {
      parent_id,
      primeiro_nome,
      sobrenome,
      nome_meio,
      data_nascimento,
      genero,
      num_cartao,
      activo,
      num_processo,
      b_i,
    } = clientData;

    if (
      num_processo === "" ||
      primeiro_nome === "" ||
      nome_meio === "" ||
      sobrenome === "" ||
      data_nascimento === "" ||
      activo === "" ||
      num_cartao === "" ||
      parent_id === "" ||
      genero === "" ||
      b_i === ""
    ) {
      setErrorVisible(true);
      setErrorMessage("Erro: Existem dados que precisam ser preenchidos");
    } else {
      setErrorVisible(false);
      handleRegistration();
    }
  };

  const getQrCode = async () => {
    const result = await getParentQrCode(
      clientData.parent_id,
      clientData.primeiro_nome,
      clientData.sobrenome,
      clientData.nome_meio,
      clientData.num_cartao,
      clientData.b_i,
      clientData.activo
    );
    if (result.success_message) {
      setqrCodeMessage("QR criado com sucesso");
      setQrErrorVisible(!qrErrorVisible);
    } else {
      setqrCodeMessage("QR não criado, tente novamente");
      setQrErrorVisible(!qrErrorVisible);
    }
  };

  const checkavailability = async () => {
    const result = await getAvailable(clientData.num_processo);
    if (result.status === 200) {
      const cheio =
        parseInt(result.user.num_beneficiarios) -
        parseInt(result.user.beneficiarios_activos);
      if (cheio !== 0) {
        setfull(!full);
        setClientData({
          ...clientData,
          parent_id: uuidv4(),
        });
      }
    }
  };

  const isQrEnable = () => {
    const {
      parent_id,
      b_i,
      primeiro_nome,
      sobrenome,
      nome_meio,
      num_cartao,
      activo,
    } = clientData;

    if (
      parent_id !== "" &&
      primeiro_nome !== "" &&
      activo !== "" &&
      b_i !== "" &&
      num_cartao !== "" &&
      sobrenome !== "" &&
      nome_meio !== ""
    ) {
      setQrButtonEnable(true);
    }
  };

  useEffect(() => {
    isQrEnable();
  }, [clientData]);

  return (
    <div className="form-container container">
      <ModalBackoffice
        show={modalIsVisible}
        handleClose={handleModal}
        buttonText="ok"
      >
        <img src={modalIcon} alt="imagem do modal"></img>
        <p>Cadastro realizado com sucesso</p>
      </ModalBackoffice>
      <div className="row">
        <div className="col-md-12">
          <h2 className="parent-register-title">Novo Parente</h2>
        </div>
      </div>
      {errorVisible && <h1>{errorMessage}</h1>}
      <div>
        {/*parent id, número do processo e número do cartao*/}
        <div div className="row num_process_container">
          {/*número do processo*/}
          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group parent-register-form-group"}>
              <input
                id="num_processo"
                type="text"
                placeholder="Insira o Número do processo"
                className="form-control parent-register-input"
                value={clientData.num_processo}
                maxLength={10}
                onChange={(text) =>
                  setClientData({
                    ...clientData,
                    num_processo: text.target.value,
                  })
                }
              />
            </div>
            {errorVisible && !clientData.num_processo && (
              <div className="register-parent-help-block">
                Campo número do processo é necessário
              </div>
            )}
          </div>

          {/*número do processo*/}
          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group parent-register-form-group"}>
              <input
                id="num_processo"
                type="text"
                placeholder="Código do Parente"
                className="form-control parent-register-input"
                value={clientData.parent_id}
                readOnly={full}
              />
            </div>
            {errorVisible && !clientData.parent_id && (
              <div className="register-parent-help-block">
                Campo código do parente é necessário
              </div>
            )}
          </div>

          {/*número do cartao*/}
          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group parent-register-form-group"}>
              <input
                id="num_cartao"
                type="text"
                placeholder="Número do cartão"
                className="form-control parent-register-input"
                value={clientData.num_cartao || ""}
                readOnly
              />
            </div>
            {errorVisible && !clientData.num_cartao && (
              <div className="register-parent-help-block">
                Campo número do cartão é necessário
              </div>
            )}
          </div>
        </div>

        {/*primeiro_nome ,sobrenome e nome do meio*/}
        <div className="row header-container">
          {/*primeiro_nome*/}
          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group parent-register-form-group"}>
              <input
                placeholder="Insira seu primeiro nome"
                id="primeiro-nome"
                type="text"
                readOnly={full}
                className="form-control parent-register-input"
                value={clientData.primeiro_nome}
                onChange={(text) =>
                  setClientData({
                    ...clientData,
                    primeiro_nome: text.target.value,
                  })
                }
              />
            </div>
            {errorVisible && !clientData.primeiro_nome && (
              <div className="register-parent-help-block">
                Campo Primeiro nome é necessário
              </div>
            )}
          </div>
          {/*sobrenome*/}
          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group parent-register-form-group"}>
              <input
                placeholder="Insira seu sobrenome"
                id="sobrenonome"
                type="text"
                readOnly={full}
                className="form-control parent-register-input"
                value={clientData.sobrenome}
                onChange={(text) =>
                  setClientData({
                    ...clientData,
                    sobrenome: text.target.value,
                  })
                }
              />
            </div>
            {errorVisible && !clientData.sobrenome && (
              <div className="register-parent-help-block">
                Campo sobrenome é necessário
              </div>
            )}
          </div>
          {/*nome do meio*/}
          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group parent-register-form-group"}>
              <input
                placeholder="Insira seu(os) nome(es) do meio"
                id="nome-meio"
                type="text"
                readOnly={full}
                className="form-control parent-register-input"
                value={clientData.nome_meio}
                onChange={(text) =>
                  setClientData({
                    ...clientData,
                    nome_meio: text.target.value,
                  })
                }
              />
            </div>
            {errorVisible && !clientData.nome_meio && (
              <div className="register-parent-help-block">
                Campo Nome(es) do meio é necessário
              </div>
            )}
          </div>
        </div>

        {/*data de nascimento e genero*/}
        <div className="row age-sex-container">
          {/*data de nascimento*/}
          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group parent-register-form-group"}>
              <DatePicker
                className="parent-register-input"
                placeholderText="Selecionar a data de nascimento"
                selected={startDate}
                dateFormat="MM/dd/yyyy"
                onChange={(date) => {
                  setStartDate(date);
                  setClientData({
                    ...clientData,
                    data_nascimento: moment(startDate).format("YYYY-MM-DD"),
                  });
                }}
              />
            </div>
            {errorVisible && !clientData.data_nascimento && (
              <div className="register-parent-help-block">
                Campo data de nascimento é necessário
              </div>
            )}
          </div>
          {/*genero*/}
          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group parent-register-form-group"}>
              <select
                className="form-control  form-select parent-register-select"
                value={clientData.genero}
                readOnly={full}
                onChange={(text) =>
                  setClientData({
                    ...clientData,
                    genero: text.target.value,
                  })
                }
              >
                <option value="">Favor selecionar o genero</option>
                <option value="masculino">Masculino</option>
                <option value="feminino">Feminino</option>
                <option value="n/a">N/A</option>
              </select>
            </div>
          </div>

          {/*BI*/}
          <div className="col-md-4 col-md-offset-3">
            <div
              className={
                "form-group parent-register-form-group" +
                (errorVisible && !clientData.b_i
                  ? " register-parent-has-error"
                  : "")
              }
            >
              <input
                id="bi"
                type="text"
                readOnly={full}
                placeholder="Insira o número da sua identificação"
                className="form-control parent-register-input"
                value={clientData.b_i}
                onChange={(text) =>
                  setClientData({
                    ...clientData,
                    b_i: text.target.value,
                  })
                }
              />
            </div>
            {errorVisible && !clientData.b_i && (
              <div className="register-parent-help-block">
                Campo B.I é necessário
              </div>
            )}
          </div>
        </div>

        {/*email , contacto e activo*/}
        <div className="row contact-container">
          {/*email*/}
          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group parent-register-form-group"}>
              <input
                id="email"
                placeholder="meunome@exemplo.co"
                type="email"
                readOnly={full}
                className="form-control parent-register-input"
                value={clientData.email}
                onChange={(text) =>
                  setClientData({
                    ...clientData,
                    email: text.target.value,
                  })
                }
              />
            </div>
          </div>
          {/*contacto*/}
          <div className="col-md-4 col-md-offset-3">
            <div
              className={
                "form-group parent-register-form-group" +
                (errorVisible && !clientData.contacto
                  ? " register-parent-has-error"
                  : "")
              }
            >
              <input
                id="contacto"
                type="text"
                readOnly={full}
                placeholder="Coloque o contacto"
                className="form-control parent-register-input"
                value={clientData.contacto}
                onChange={(text) =>
                  setClientData({
                    ...clientData,
                    contacto: text.target.value,
                  })
                }
              />
            </div>
          </div>

          {/*activo*/}
          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group parent-register-form-group"}>
              <select
                className="form-control  form-select parent-register-select"
                value={clientData.activo}
                readOnly={full}
                onChange={(text) =>
                  setClientData({
                    ...clientData,
                    activo: text.target.value,
                  })
                }
              >
                <option value="">Favor selecionar o estado do cliente</option>
                <option value="0">Desativado</option>
                <option value="1">Activado</option>
              </select>
            </div>
            {errorVisible && !clientData.activo && (
              <div className="register-parent-help-block">
                Campo Activo é necessário
              </div>
            )}
          </div>
        </div>

        {/*historico  */}
        <div className="row history-container">
          <div className="col-md-12 col-md-offset-3">
            <div className={"form-group parent-register-form-group"}>
              <textarea
                id="historico"
                type="text"
                readOnly={full}
                placeholder="Historico"
                className="form-control parent-register-input"
                value={clientData.historico}
                onChange={(text) =>
                  setClientData({
                    ...clientData,
                    historico: text.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        {/*botões*/}
        <div className="row qr-container">
          <div className="col-md-3 col-md-offset-3">
            <div className={"form-group parent-register-form-group"}>
              <button
                disabled={!clientData.num_processo || full === false}
                className="btn parent-register-btn"
                onClick={checkavailability}
              >
                Ver Disponibilidade
              </button>
            </div>
          </div>

          <div className="col-md-3 col-md-offset-3">
            <button
              disabled={!qrButtonEnable}
              className="btn parent-register-btn"
              onClick={getQrCode}
            >
              Gerar Código QR
            </button>
            {qrErrorVisible && <p>{qrCodeMessage}</p>}
          </div>

          <div className="col-md-3 col-md-offset-3">
            <div className={"form-group parent-register-form-group"}>
              <button className="btn parent-register-btn" onClick={handleClean}>
                Limpar
              </button>
            </div>
          </div>

          <div className="col-md-3 col-md-offset-3">
            <div className={"form-group parent-register-form-group"}>
              <button
                className="btn parent-register-btn"
                onClick={doSubmission}
              >
                Registrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
