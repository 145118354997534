import React from "react";
import { Section } from "../../components/Section";
import { Footer } from "../../components/Footer";
import logoImg from "../../images/logo-cartao-saude.png";
import { ImageSlider } from "../../components/ImageSlider";
import { SliderData } from "../../utils/SliderData";

import "./style.css";

export const Home = () => {
  return (
    <>
      <div className="banner">
        <Section>
          <div className="row ">
            <div className="col-md-12 banner-principal-container">
              <div>
                <h1 className="banner-principal-title">
                  O 1º Plano de Saúde <br />
                  para estudantes
                </h1>

                <a className="ler-mais-links" href="#planos">
                  Ler mais
                </a>
              </div>
            </div>
          </div>
        </Section>
      </div>
      <div className="capa-content">
        <Section type="white">
          <div className="row ">
            <div className="col-md-4 capa-logo-container">
              <img src={logoImg} alt="Logo do cartão familia" />
            </div>
            <div className="col-md-2 capa-separator-container">
              <div className="capa-separator-container-content">
                <div className="capa-separator"></div>
              </div>
            </div>
            <div className="col-md-6 capa-text-container">
              <p className="capa-text-container-text">
                Um cartão que concede benefícios muito especiais e exclusivos em
                toda a rede de parceiros da UPRA, sempre que desejar, sem
                limites, sem carências, sem considerar condições de sáude e/ou
                doenças pré-existentes, idade ou sexo. O acesso a esta rede é
                imediato após a assinatura e pagamento da taxa anual de adesão.
              </p>
            </div>
          </div>
          <div className="row capa-benefits-container">
            <div className="col-md-4 capa-benefits-content">
              <p className="capa-benefits-content-title">Fácil adesão</p>

              <p className="capa-benefits-content-info">
                Melhores profissionais, todos as sua disposição
              </p>
            </div>
            <div className="col-md-4 capa-benefits-content">
              <p className="capa-benefits-content-title">
                Para Estudantes, Ex-Estudantes da UPRA,
                <br /> bem como seus familiares
              </p>

              <p className="capa-benefits-content-info">
                Proteja-se a si e toda a sua familia
              </p>
            </div>
            <div className="col-md-4 capa-benefits-content">
              <p className="capa-benefits-content-title">
                Serviço diferenciado
              </p>

              <p className="capa-benefits-content-info">
                Exames, atendimento preferencial e consultas
              </p>
            </div>
          </div>
        </Section>
      </div>
      <div className="content-a">
        <Section>
          {<ImageSlider slides={SliderData} />}
          {/* começo */}
          {/* <div className="content-a-container">
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img className="d-block" src={"ouroImg"} alt="First slide" />
                </div>
                <div className="carousel-item">
                  <img
                    className="d-block "
                    src={"bronzeImg"}
                    alt="Second slide"
                  />
                </div>
                <div className="carousel-item">
                  <img className="d-block" src={"prataImg"} alt="Third slide" />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#carouselExampleControls"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#carouselExampleControls"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div> */}
          {/* fim */}
        </Section>
      </div>
      <div className="content-b">
        <Section type="white">
          <h4 className="content-b-second-subtitle">Sobre nós</h4>
          <div className="row why-to-use ">
            <div className="col-md-12 why-to-use-container">
              <p className="why-to-use-text">
                Sabemos cuidar dos nossos! Preocupamo-nos com a sua saúde e
                bem-estar. Por isso criamos para si e o seu núcleo familiar o
                CARTÃO FAMÍLIA UPRA SAÚDE
              </p>
              <p className="why-to-use-text">
                Por fazermos parte da mesma família, proporcionamos-lhe a si e
                aos seus entes queridos acesso a um Atendimento Personalizado e
                de Excelência nas nossas Policlínicas Universitárias com
                inúmeras vantagens e benefícios concedidos pelos Parceiros da
                rede Cartão FAMÍLIA UPRA Saúde!
              </p>

              <p className="why-to-use-text">
                Trata-se de um cartão que concede benefícios muito especiais e
                exclusivos em toda a rede de parceiros da UPRA, sempre que
                desejar, sem limites, sem carências, sem considerar condições de
                sáude e/ou doenças pré-existentes, idade ou sexo. O acesso a
                esta rede é imediato após a assinatura e pagamento da taxa anual
                de adesão.
              </p>
            </div>
          </div>
        </Section>
      </div>
      <div className="content-c">
        <Section type="white">
          <div className="content-c-container">
            <h1 className="aquire-plan-title">
              Adira já o seu plano Família UPRA
            </h1>
            <p className="aquire-plan-text">Entre agora em contacto!</p>
            <a className="content-c-link" href="contato">
              CONTACTAR AGORA
            </a>
          </div>
        </Section>
      </div>
      <div className="content-d" />
      <Footer />
    </>
  );
};
