import React from "react";
import "./style.css";
import { Section } from "../../components/Section";
export const Contact = () => {
  return (
    <div className="">
      <Section type="contact">
        <div className="row banner-contact-container">
          <div className="col-md-6 contact-background" />
          <div className="col-md-4">
            <div className="">
              <h6 className="contact-title">Contacte-nos!</h6>
              <p className="contact-text">Telefone:+244 947 632 682</p>
              <p className="contact-text">email: geral@familiaupra.ao</p>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
};
