import React from "react";

import "./style.css";

export const ModalBackoffice = ({
  handleClose,
  show,
  buttonText,
  children,
}) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="modal-container">
        <div className="modal-image-text-content">{children}</div>

        <button className="btn user-registration-btn" onClick={handleClose}>
          {buttonText}
        </button>
      </section>
    </div>
  );
};
