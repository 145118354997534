import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { NavBar } from "./components/NavBar";
import { Home } from "./pages/Home";
import { Contact } from "./pages/Contact";
import { Login } from "./pages/Login";
import { createBrowserHistory } from "history";
import { ROUTES, RenderRoutes } from "./components/Routes";
import { ShowHideNavbar } from "./components/ShowHideNavbar";

const history = createBrowserHistory();

function App() {
  return (
    <Router history={history}>
      <ShowHideNavbar />
      {/* <NavBar />
      <div className="separator"></div> */}
      {/* <Switch>
        <Route path="/" exact component={Home}></Route>
        <Route path="/contato" exact component={Contact}></Route>
        <Route path="/entrar" exact component={Login}></Route>
        <Route path="/dashboard" exact component={Dashboard}></Route>
        <Route
          path="/dashboard/procurar-editar"
          exact
          component={DashboardSearchEditClient}
        ></Route>
        <Route
          path="/dashboard/cadastrar-novo-cliente"
          exact
          component={DashboardClientRegistration}
        ></Route>
        <Route
          path="/dashboard/cadastrar-novo-usuario"
          exact
          component={DashboardBackOfficeUserRegistration}
        ></Route>
        <Route
          path="/dashboard/editar-cliente/:id"
          exact
          component={DashboardClientRegistration}
        ></Route>
      </Switch> */}
      <RenderRoutes routes={ROUTES}></RenderRoutes>
    </Router>
  );
}

export default App;
