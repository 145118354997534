import React from "react";

import "./style.css";
import { SideBar } from "../../components/SiderBar";
import Layout from "../../components/Layout";
import { ClientRegisterForm } from "../../components/ClientResgisterForm";

export const DashboardClientRegistration = () => {
  return (
    <div className="row g-0">
      <div className="col-md-2">
        <SideBar></SideBar>
      </div>
      <div className="col-md-10">
        <Layout>
          <ClientRegisterForm />
        </Layout>
      </div>
    </div>
  );
};
