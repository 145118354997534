import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "./style.css";
import {
  doClientRegistration,
  doGetMunicipios,
  doUploadFiles,
  doUploadImage,
  doGetQr,
  doClientSearchByProcess,
  doClientUpdate,
} from "../../services/Conection";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ModalBackoffice } from "../ModalBackoffice";
import modalIcon from "../../images/modal_icon.png";
export const ClientRegisterForm = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const ref = useRef(null);
  const ref1 = useRef(null);
  const [errorVisible, setErrorVisible] = useState(false);
  const [qrButtonEnable, setQrButtonEnable] = useState(false);
  const [startDate, setStartDate] = useState();
  const [avaiableMunicipios, setAvaiableMunicipios] = useState([]);
  const [qrErrorVisible, setQrErrorVisible] = useState(false);
  const [qrCodeMessage, setqrCodeMessage] = useState("");
  const [clientData, setClientData] = useState({
    num_processo: "",
    primeiro_nome: "",
    sobrenome: "",
    nome_meio: "",
    data_nascimento: "",
    genero: "",
    b_i: "",
    endereco: {
      rua: "",
      bairro: "",
      municipio: "",
    },
    plano: "",
    historico: "",
    num_beneficiarios: "",
    beneficiarios_activos: "",
    contacto: "",
    email: "",
    num_cartao: "",
    activo: "",
    num_consultas: "",
    tipo_cliente: "",
    contrato_adesao: "",
    contrato_adesao_path: "",
    foto: "",
    foto_path: "",
  });
  const history = useHistory();
  const user = useSelector((state) => state.user.value);
  const location = useLocation();
  const numProcesso = location?.state?.params;
  const [modalIsVisible, setModalIsVisible] = useState(false);

  const handleModal = () => {
    setModalIsVisible(!modalIsVisible);
  };

  const handleRegistration = async () => {
    const result = await doClientRegistration(clientData);
    if (result.err || result === undefined) {
      setErrorMessage("Erro: Não é possivel gravar úsuarios com o mesmo nome");
      setErrorVisible(!errorVisible);
    }
    if (result.user.num_processo || result.status === 200) {
      handleClean();
      setqrCodeMessage("");
      setQrErrorVisible(!qrErrorVisible);
      setModalIsVisible(!modalIsVisible);
    }
  };

  const handleUpdate = async () => {
    const result = await doClientUpdate(clientData);
    console.log("asds", result);
    if (result.err || result === undefined) {
      setErrorMessage("Erro: Não é possivel gravar úsuarios com o mesmo nome");
      setErrorVisible(!errorVisible);
    }
    if (result.user.num_processo || result.status === 200) {
      handleClean();
      setqrCodeMessage("");
      setQrErrorVisible(!qrErrorVisible);
      setModalIsVisible(!modalIsVisible);
    }
  };

  const handleUpdateByEdit = async () => {
    const result = await doClientSearchByProcess(numProcesso);
    if (result.err || result === undefined) {
      setErrorMessage("Erro: O usúario não foi encontrado");
      setErrorVisible(!errorVisible);
    }
    if (result.user.num_processo) {
      setClientData({
        num_processo: result.user.num_processo,
        primeiro_nome: result.user.primeiro_nome,
        sobrenome: result.user.sobrenome,
        nome_meio: result.user.nome_meio,
        data_nascimento: result.user.data_nascimento,
        genero: result.user.genero,
        b_i: result.user.b_i,
        endereco: {
          rua: result.user.rua,
          bairro: result.user.bairro,
          municipio: result.user.municipio,
        },
        plano: result.user.plano,
        historico: result.user.historico,
        num_beneficiarios: result.user.num_beneficiarios,
        beneficiarios_activos: result.user.beneficiarios_activos,
        contacto: result.user.contacto,
        email: result.user.email,
        tipo_cliente: result.user.tipo_cliente,
        activo: result.user.activo,
        num_consultas: result.user.num_consultas,
        contrato_adesao: "",
        foto: "",
      });
    }
  };
  useEffect(() => {
    if (!user.token) {
      history.push("/entrar", { from: "cadastrar-novo-cliente" });
    }

    if (
      numProcesso !== "" &&
      numProcesso !== undefined &&
      clientData.num_processo === ""
    ) {
      handleUpdateByEdit();
    }
  });

  const getClientType = (value) => {
    if (value === "10") {
      return "UPRA";
    }
    if (value === "20") {
      return "EXTERNO";
    }
  };

  const getMunicipios = async () => {
    const result = await doGetMunicipios();
    if (result) {
      setAvaiableMunicipios(result);
    } else {
      alert(
        "Lista de municipios não carregada, actualizar o site para carrega-la"
      );
    }
  };

  const handleClean = () => {
    setClientData({
      num_processo: "",
      primeiro_nome: "",
      sobrenome: "",
      nome_meio: "",
      data_nascimento: "",
      genero: "",
      b_i: "",
      endereco: {
        rua: "",
        bairro: "",
        municipio: "",
      },
      plano: "",
      historico: "",
      num_beneficiarios: "",
      beneficiarios_activos: "",
      contacto: "",
      email: "",
      num_cartao: "",
      activo: "",
      num_consultas: "",
      tipo_cliente: "",
      contrato_adesao: "",
      foto: "",
    });
    if (ref.current) {
      ref.current.value = "";
    }
    if (ref1.current) {
      ref1.current.value = "";
    }
    setStartDate();
  };

  const uploadImage = async (e) => {
    const result = await doUploadImage(
      e.target.files[0],
      clientData.num_processo
    );
    if (result.status) {
      setClientData({
        ...clientData,
        foto: result.file_name,
        foto_path: result.file_path,
      });
    }
  };

  const uploadFile = async (e) => {
    const result = await doUploadFiles(
      e.target.files[0],
      clientData.num_processo
    );
    if (result.status) {
      setClientData({
        ...clientData,
        contrato_adesao: result.file_name,
        contrato_adesao_path: result.file_path,
      });
    }
  };

  const updateCardNumber = () => {
    setClientData({
      ...clientData,
      num_cartao:
        clientData.num_processo +
        clientData.plano +
        clientData.num_beneficiarios,
    });
  };

  useEffect(() => {
    if (clientData.num_processo !== "" && clientData.plano !== "") {
      updateCardNumber();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData.num_processo, clientData.plano]);

  useEffect(() => {
    getMunicipios();
  }, []);

  const doSubmission = () => {
    const {
      num_processo,
      b_i,
      primeiro_nome,
      nome_meio,
      sobrenome,
      data_nascimento,
      activo,
      num_cartao,
      plano,
      beneficiarios_activos,
    } = clientData;
    if (
      num_processo === "" ||
      num_processo === undefined ||
      primeiro_nome === "" ||
      nome_meio === "" ||
      sobrenome === "" ||
      data_nascimento === "" ||
      activo === "" ||
      num_cartao === "" ||
      plano === "" ||
      beneficiarios_activos === "" ||
      b_i === ""
    ) {
      setErrorVisible(true);
      setErrorMessage("Erro: Existem dados que precisam ser preenchidos");
    } else {
      setErrorVisible(false);
      handleRegistration();
    }
  };

  const doUpdate = () => {
    const {
      num_processo,
      b_i,
      primeiro_nome,
      nome_meio,
      sobrenome,
      activo,
      num_cartao,
      plano,
      beneficiarios_activos,
    } = clientData;
    if (
      num_processo === "" ||
      num_processo === undefined ||
      primeiro_nome === "" ||
      nome_meio === "" ||
      sobrenome === "" ||
      activo === "" ||
      num_cartao === "" ||
      plano === "" ||
      beneficiarios_activos === "" ||
      b_i === ""
    ) {
      setErrorVisible(true);
      setErrorMessage("Erro: Existem dados que precisam ser preenchidos");
    } else {
      setErrorVisible(false);
      handleUpdate();
    }
  };

  const getQrCode = async () => {
    const result = await doGetQr(
      clientData.num_processo,
      clientData.primeiro_nome,
      clientData.sobrenome,
      clientData.nome_meio,
      clientData.num_cartao,
      clientData.b_i,
      clientData.activo
    );
    if (result.success_message) {
      setqrCodeMessage("QR criado com sucesso");
      setQrErrorVisible(!qrErrorVisible);
    } else {
      setqrCodeMessage("QR não criado, tente novamente");
      setQrErrorVisible(!qrErrorVisible);
    }
  };

  const isQrEnable = () => {
    const {
      num_processo,
      b_i,
      primeiro_nome,
      sobrenome,
      nome_meio,
      num_cartao,
      activo,
    } = clientData;

    if (
      num_processo !== "" &&
      primeiro_nome !== "" &&
      activo !== "" &&
      b_i !== "" &&
      num_cartao !== "" &&
      sobrenome !== "" &&
      nome_meio !== ""
    ) {
      setQrButtonEnable(true);
    }
  };

  useEffect(() => {
    isQrEnable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData]);

  return (
    <div className="form-container container">
      <ModalBackoffice
        show={modalIsVisible}
        handleClose={handleModal}
        buttonText="ok"
      >
        <img src={modalIcon} alt="imagem do modal"></img>
        <p>Cadastro realizado com sucesso</p>
      </ModalBackoffice>
      <div className="row">
        <div className="col-md-12">
          <h2 className="client-register-title">Novo Cliente</h2>
        </div>
      </div>
      {errorVisible && <h1>{errorMessage}</h1>}
      <div>
        {/*primeiro_nome ,sobrenome e nome do meio*/}
        <div className="row header-container">
          {/*primeiro_nome*/}
          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group client-register-form-group"}>
              <input
                placeholder="Insira seu primeiro nome"
                id="primeiro-nome"
                type="text"
                className="form-control client-register-input"
                value={clientData.primeiro_nome}
                onChange={(text) =>
                  setClientData({
                    ...clientData,
                    primeiro_nome: text.target.value,
                  })
                }
              />
            </div>
            {errorVisible && !clientData.primeiro_nome && (
              <div className="register-client-help-block">
                Campo Primeiro nome é necessário
              </div>
            )}
          </div>
          {/*sobrenome*/}
          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group client-register-form-group"}>
              <input
                placeholder="Insira seu sobrenome"
                id="sobrenonome"
                type="text"
                className="form-control client-register-input"
                value={clientData.sobrenome}
                onChange={(text) =>
                  setClientData({
                    ...clientData,
                    sobrenome: text.target.value,
                  })
                }
              />
            </div>
            {errorVisible && !clientData.sobrenome && (
              <div className="register-client-help-block">
                Campo sobrenome é necessário
              </div>
            )}
          </div>
          {/*nome do meio*/}
          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group client-register-form-group"}>
              <input
                placeholder="Insira seu(os) nome(es) do meio"
                id="nome-meio"
                type="text"
                className="form-control client-register-input"
                value={clientData.nome_meio}
                onChange={(text) =>
                  setClientData({
                    ...clientData,
                    nome_meio: text.target.value,
                  })
                }
              />
            </div>
            {errorVisible && !clientData.nome_meio && (
              <div className="register-client-help-block">
                Campo Nome(es) do meio é necessário
              </div>
            )}
          </div>
        </div>

        {/*data de nascimento e genero*/}
        <div className="row age-sex-container">
          {/*data de nascimento*/}
          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group client-register-form-group"}>
              <DatePicker
                className="client-register-input"
                placeholderText="Selecionar a data de nascimento"
                selected={startDate}
                dateFormat="MM/dd/yyyy"
                onChange={(date) => {
                  setStartDate(date);
                  setClientData({
                    ...clientData,
                    data_nascimento: moment(startDate).format("YYYY-MM-DD"),
                  });
                }}
              />
            </div>
            {errorVisible && !clientData.data_nascimento && (
              <div className="register-client-help-block">
                Campo data de nascimento é necessário
              </div>
            )}
          </div>
          {/*genero*/}
          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group client-register-form-group"}>
              <select
                className="form-control  form-select client-register-select"
                value={clientData.genero}
                onChange={(text) =>
                  setClientData({
                    ...clientData,
                    genero: text.target.value,
                  })
                }
              >
                <option value="">Favor selecionar o genero</option>
                <option value="masculino">Masculino</option>
                <option value="feminino">Feminino</option>
                <option value="n/a">N/A</option>
              </select>
            </div>
          </div>

          {/*BI*/}
          <div className="col-md-4 col-md-offset-3">
            <div
              className={
                "form-group client-register-form-group" +
                (errorVisible && !clientData.b_i
                  ? " register-client-has-error"
                  : "")
              }
            >
              <input
                id="bi"
                type="text"
                placeholder="Insira o número da sua identificação"
                className="form-control client-register-input"
                value={clientData.b_i}
                onChange={(text) =>
                  setClientData({
                    ...clientData,
                    b_i: text.target.value,
                  })
                }
              />
            </div>
            {errorVisible && !clientData.b_i && (
              <div className="register-client-help-block">
                Campo B.I é necessário
              </div>
            )}
          </div>
        </div>
        {/*endereço: rua, bairro, municipio*/}
        <div className="row adress-container">
          {/*Bairro*/}
          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group client-register-form-group"}>
              <input
                id="bairro"
                placeholder="Nome do Bairro"
                type="text"
                className="form-control client-register-input"
                value={clientData.endereco.bairro}
                onChange={(text) =>
                  setClientData({
                    ...clientData,
                    endereco: {
                      ...clientData.endereco,
                      bairro: text.target.value,
                    },
                  })
                }
              />
            </div>
          </div>

          {/*municipio*/}
          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group client-register-form-group"}>
              <select
                className="form-control  form-select client-register-select"
                value={clientData.endereco.municipio}
                onChange={(text) =>
                  setClientData({
                    ...clientData,
                    endereco: {
                      ...clientData.endereco,
                      municipio: parseInt(text.target.value),
                    },
                  })
                }
              >
                <option value="">Favor selecionar o municipio</option>
                {avaiableMunicipios.length > 0 &&
                  avaiableMunicipios.map((value, i) => (
                    <option key={value.id} value={value.id}>
                      {value.nome}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          {/*Rua*/}
          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group client-register-form-group"}>
              <input
                id="rua"
                type="text"
                placeholder="Insira o nome da rua"
                className="form-control client-register-input"
                value={clientData.endereco.rua}
                onChange={(text) =>
                  setClientData({
                    ...clientData,
                    endereco: {
                      ...clientData.endereco,
                      rua: text.target.value,
                    },
                  })
                }
              />
            </div>
          </div>
        </div>
        {/*email , contacto e activo*/}
        <div className="row contact-container">
          {/*email*/}
          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group client-register-form-group"}>
              <input
                id="email"
                placeholder="meunome@exemplo.co"
                type="email"
                className="form-control client-register-input"
                value={clientData.email}
                onChange={(text) =>
                  setClientData({
                    ...clientData,
                    email: text.target.value,
                  })
                }
              />
            </div>
          </div>
          {/*contacto*/}
          <div className="col-md-4 col-md-offset-3">
            <div
              className={
                "form-group client-register-form-group" +
                (errorVisible && !clientData.contacto
                  ? " register-client-has-error"
                  : "")
              }
            >
              <input
                id="contacto"
                type="text"
                placeholder="Coloque o contacto"
                className="form-control client-register-input"
                value={clientData.contacto}
                onChange={(text) =>
                  setClientData({
                    ...clientData,
                    contacto: text.target.value,
                  })
                }
              />
            </div>
          </div>

          {/*activo*/}
          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group client-register-form-group"}>
              <select
                className="form-control  form-select client-register-select"
                value={clientData.activo}
                onChange={(text) =>
                  setClientData({
                    ...clientData,
                    activo: text.target.value,
                  })
                }
              >
                <option value="">Favor selecionar o estado do cliente</option>
                <option value="0">Desativado</option>
                <option value="1">Activado</option>
              </select>
            </div>
            {errorVisible && !clientData.activo && (
              <div className="register-client-help-block">
                Campo Activo é necessário
              </div>
            )}
          </div>
        </div>

        {/*tipo de cliente, número do processo e número do cartao*/}
        <div className="row num_process_container">
          {/*número do processo*/}
          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group client-register-form-group"}>
              <input
                id="num_processo"
                type="text"
                placeholder="Insira o Número do processo"
                className="form-control client-register-input"
                value={clientData.num_processo}
                maxLength={10}
                onChange={(text) =>
                  setClientData({
                    ...clientData,
                    num_processo: text.target.value,
                    tipo_cliente: getClientType(
                      text.target.value.substring(0, 2)
                    ),
                  })
                }
              />
            </div>
            {errorVisible && !clientData.num_processo && (
              <div className="register-client-help-block">
                Campo número do processo é necessário
              </div>
            )}
          </div>

          {/*tipo de  Cliente*/}
          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group client-register-form-group"}>
              <input
                id="tipo_cliente"
                type="text"
                placeholder="Tipo de cliente"
                className="form-control client-register-input"
                value={clientData.tipo_cliente || ""}
                readOnly
              />
            </div>
            {/* {errorVisible && !clientData.tipo_cliente && (
              <div className="register-client-help-block">
                Campo tipo de cliente é necessário
              </div>
            )} */}
          </div>

          {/*número do cartao*/}
          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group client-register-form-group"}>
              <input
                id="num_cartao"
                type="text"
                placeholder="Número do cartão"
                className="form-control client-register-input"
                value={clientData.num_cartao || ""}
                readOnly
              />
            </div>
            {errorVisible && !clientData.num_cartao && (
              <div className="register-client-help-block">
                Campo número do cartão é necessário
              </div>
            )}
          </div>
        </div>

        {/*plano , numero de beneficiários, beneficiarios activos*/}
        <div className="row plan-benefits-container">
          {/*plano*/}
          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group client-register-form-group"}>
              <select
                className="form-control  form-select client-register-select"
                value={clientData.plano}
                onChange={(text) => {
                  setClientData({
                    ...clientData,
                    plano: text.target.value,
                    num_beneficiarios:
                      text.target.value === "BR"
                        ? "4"
                        : text.target.value === "PRT"
                        ? "6"
                        : text.target.value === "OU"
                        ? "8"
                        : text.target.value === "PLA"
                        ? "12"
                        : "",
                  });
                }}
              >
                <option value="">Favor selecionar o plano</option>
                <option value="BR">Bronze</option>
                <option value="PRT">Prata</option>
                <option value="OU">Ouro</option>
                <option value="PLA">Platina</option>
              </select>
            </div>
            {errorVisible && !clientData.plano && (
              <div className="register-client-help-block">
                Campo plano é necessário
              </div>
            )}
          </div>

          {/*beneficiarios*/}
          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group client-register-form-group"}>
              <input
                id="num_beneficiarios"
                type="text"
                placeholder="Número de beneficiarios"
                className="form-control client-register-input"
                value={clientData.num_beneficiarios || ""}
                readOnly
              />
            </div>
          </div>

          {/*beneficiários activos*/}
          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group client-register-form-group"}>
              <input
                id="beneficiarios_activos"
                type="text"
                placeholder="Beneficiários activos"
                className="form-control client-register-input"
                value={clientData.beneficiarios_activos}
                onChange={(text) =>
                  setClientData({
                    ...clientData,
                    beneficiarios_activos: text.target.value,
                  })
                }
              />
            </div>
            {errorVisible && !clientData.beneficiarios_activos && (
              <div className="register-client-help-block">
                Campo beneficiários actvios é necessário
              </div>
            )}
          </div>
        </div>

        {/*foto e contrato*/}
        <div className="row contract-foto-container">
          {/*contrato*/}
          {clientData.num_processo !== undefined &&
            clientData.num_processo !== "" && (
              <div className="col-md-6 col-md-offset-3">
                <div className={"form-group client-register-form-group"}>
                  <label
                    for="contrato"
                    className="client-register-file-input-label"
                  >
                    Contrato de adesão
                  </label>
                  <input
                    placeholder="Contrato de adesão"
                    id="contrato_adesao"
                    type="file"
                    ref={ref}
                    name="application"
                    className="form-control client-register-input"
                    onChange={(e) => uploadFile(e)}
                  />
                </div>
              </div>
            )}

          {/*foto*/}
          {clientData.num_processo !== undefined &&
            clientData.num_processo !== "" && (
              <div className="col-md-6 col-md-offset-3">
                <div className={"form-group client-register-form-group"}>
                  <label
                    for="foto"
                    className="client-register-file-input-label"
                  >
                    Foto
                  </label>
                  <input
                    id="foto"
                    placeholder="Foto"
                    type="file"
                    name="image"
                    ref={ref1}
                    className="form-control client-register-input"
                    onChange={(e) => uploadImage(e)}
                  />
                </div>
              </div>
            )}
        </div>

        {/*historico  */}
        <div className="row history-container">
          <div className="col-md-12 col-md-offset-3">
            <div className={"form-group client-register-form-group"}>
              <textarea
                id="historico"
                type="text"
                placeholder="Historico"
                className="form-control client-register-input"
                value={clientData.historico}
                onChange={(text) =>
                  setClientData({
                    ...clientData,
                    historico: text.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        {/*qr code*/}
        <div className="row qr-container">
          {/**Parte para gerar o qr code */}
          {/* <div className="col-md-4 col-md-offset-3">
            <button
              disabled={!qrButtonEnable}
              className="btn client-register-btn"
              onClick={getQrCode}
            >
              Gerar Código QR
            </button>
            {qrErrorVisible && <p>{qrCodeMessage}</p>}
          </div> */}

          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group client-register-form-group"}>
              <button className="btn client-register-btn" onClick={handleClean}>
                Limpar
              </button>
            </div>
          </div>

          <div className="col-md-4 col-md-offset-3">
            <div className={"form-group client-register-form-group"}>
              <button
                disabled={
                  clientData.num_processo === "" ||
                  clientData.num_processo === undefined
                }
                className="btn client-register-btn"
                onClick={numProcesso ? doUpdate : doSubmission}
              >
                {numProcesso ? "Actualizar" : "Registrar"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
