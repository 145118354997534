import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, Navbar, NavItem, NavLink as BSNavLink } from "reactstrap";
import { useLocation } from "react-router-dom";
import SourceLink from "../SourceLink";
import { AiOutlineHome } from "react-icons/ai";
import { BiUserPlus } from "react-icons/bi";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { MdPersonSearch } from "react-icons/md";
import { AiOutlineSetting } from "react-icons/ai";
import { useSelector } from "react-redux";

import "./style.css";

export const SideBar = () => {
  const location = useLocation();
  const user = useSelector((state) => state.user.value);
  const { role } = user;
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  return (
    <div className="content">
      <Navbar>
        <SourceLink className="navbar-brand d-flex">
          <p className="siderbar-title">FAMÍLIA UPRA</p>
        </SourceLink>
      </Navbar>

      {/* comeca aqui  */}
      <Nav vertical>
        <NavItem className="sidebar-navitem">
          <BSNavLink
            className=""
            tag={NavLink}
            to="/"
            activeClassName="active"
            exact={true}
          >
            <div className="sidebar-image-text-container">
              <AiOutlineHome
                color={splitLocation[2] === "" ? "#25ae60" : "#a5a4bf"}
                className="siderbar-image"
              />
              <span className="sidebar-link">Home</span>
            </div>
          </BSNavLink>
        </NavItem>

        {(role === "gestor" && (
          <NavItem className="sidebar-navitem">
            <BSNavLink
              className=""
              tag={NavLink}
              to="/dashboard/cadastrar-novo-usuario"
              activeClassName="active"
              exact={false}
            >
              <div className="sidebar-image-text-container">
                <BiUserPlus
                  color={
                    splitLocation[2] === "cadastrar-novo-usuario"
                      ? "#25ae60"
                      : "#a5a4bf"
                  }
                  className="siderbar-image"
                />
                <span className="sidebar-link">Cadastrar novo usuário</span>
              </div>
            </BSNavLink>
          </NavItem>
        )) ||
          (role === "adm" && (
            <NavItem className="sidebar-navitem">
              <BSNavLink
                className=""
                tag={NavLink}
                to="/dashboard/cadastrar-novo-usuario"
                activeClassName="active"
                exact={false}
              >
                <div className="sidebar-image-text-container">
                  <BiUserPlus
                    color={
                      splitLocation[2] === "cadastrar-novo-usuario"
                        ? "#25ae60"
                        : "#a5a4bf"
                    }
                    className="siderbar-image"
                  />
                  <span className="sidebar-link">Cadastrar novo usuário</span>
                </div>
              </BSNavLink>
            </NavItem>
          ))}
        {(role === "gestor" && (
          <NavItem className="sidebar-navitem">
            <BSNavLink
              className=""
              tag={NavLink}
              to="/dashboard/cadastrar-novo-cliente"
              activeClassName="active"
              exact={false}
            >
              <div className="sidebar-image-text-container">
                <AiOutlineUsergroupAdd
                  color={
                    splitLocation[2] === "cadastrar-novo-cliente"
                      ? "#25ae60"
                      : "#a5a4bf"
                  }
                  className="siderbar-image"
                />
                <span className="sidebar-link">Cadastrar novo cliente</span>
              </div>
            </BSNavLink>
          </NavItem>
        )) ||
          (role === "adm" && (
            <NavItem className="sidebar-navitem">
              <BSNavLink
                className=""
                tag={NavLink}
                to="/dashboard/cadastrar-novo-cliente"
                activeClassName="active"
                exact={false}
              >
                <div className="sidebar-image-text-container">
                  <AiOutlineUsergroupAdd
                    color={
                      splitLocation[2] === "cadastrar-novo-cliente"
                        ? "#25ae60"
                        : "#a5a4bf"
                    }
                    className="siderbar-image"
                  />
                  <span className="sidebar-link">Cadastrar novo cliente</span>
                </div>
              </BSNavLink>
            </NavItem>
          ))}

        {(role === "gestor" && (
          <NavItem className="sidebar-navitem">
            <BSNavLink
              className=""
              tag={NavLink}
              to="/dashboard/cadastrar-novo-parente"
              activeClassName="active"
              exact={false}
            >
              <div className="sidebar-image-text-container">
                <AiOutlineUsergroupAdd
                  color={
                    splitLocation[2] === "cadastrar-novo-parente"
                      ? "#25ae60"
                      : "#a5a4bf"
                  }
                  className="siderbar-image"
                />
                <span className="sidebar-link">Cadastrar Parente</span>
              </div>
            </BSNavLink>
          </NavItem>
        )) ||
          (role === "adm" && (
            <NavItem className="sidebar-navitem">
              <BSNavLink
                className=""
                tag={NavLink}
                to="/dashboard/cadastrar-novo-parente"
                activeClassName="active"
                exact={false}
              >
                <div className="sidebar-image-text-container">
                  <AiOutlineUsergroupAdd
                    color={
                      splitLocation[2] === "cadastrar-novo-parente"
                        ? "#25ae60"
                        : "#a5a4bf"
                    }
                    className="siderbar-image"
                  />
                  <span className="sidebar-link">Cadastrar novo Parente</span>
                </div>
              </BSNavLink>
            </NavItem>
          ))}

        <NavItem className="sidebar-navitem">
          <BSNavLink
            className=""
            tag={NavLink}
            to="/dashboard/procurar-editar"
            activeClassName="active"
            exact={false}
          >
            <div className="sidebar-image-text-container">
              <MdPersonSearch
                color={
                  splitLocation[2] === "procurar-editar" ? "#25ae60" : "#a5a4bf"
                }
                className="siderbar-image"
              />
              <span className="sidebar-link">Procurar e editar cliente</span>
            </div>
          </BSNavLink>
        </NavItem>

        {role === "adm" && (
          <NavItem className="sidebar-navitem">
            <BSNavLink
              className=""
              tag={NavLink}
              to="/dashboard/configurar"
              activeClassName="active"
              exact={false}
            >
              <div className="sidebar-image-text-container">
                <AiOutlineSetting
                  color={
                    splitLocation[2] === "configurar" ? "#25ae60" : "#a5a4bf"
                  }
                  className="siderbar-image"
                />
                <span className="sidebar-link">Configurações</span>
              </div>
            </BSNavLink>
          </NavItem>
        )}
      </Nav>
      {/*  termina aqui */}
    </div>
  );
};
