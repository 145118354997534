import React from "react";
import { LoginButton } from "../LoginButton";
import { Navbar, Container, Nav } from "react-bootstrap";
import cardLogo from "../../images/logo-cartao-saude.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import "./style.css";

export const NavBar = () => {
  const user = useSelector((state) => state.user.value);
  return (
    <>
      <div>
        <div className="row top-header">
          <div className="col-md-6 top-header-redes-container">
            <div className="row">
              <div className="col-md-6">
                <i className="fab fa-facebook-f   top-header-icon"></i>
              </div>
              <div className="col-md-6">
                <i className="fab fa-instagram  top-header-icon"></i>
              </div>
            </div>
          </div>
          <div className="col-md-6 top-header-login-container">
            <LoginButton type="top-header" />
          </div>
        </div>
      </div>
      <Navbar expand="sm" className="new-navbar" fixed="sticky-top">
        <Container>
          <Navbar.Brand href="/" className="new-navbar-logo">
            <img className="new-navbar-logo" src={cardLogo} alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="justify-content-end new-nav-menu">
              {/* antigamente era Nav.Link e href */}
              <Link className="new-nav-links" to="/">
                Início
              </Link>
              <Link className="new-nav-links" to="/contato">
                Contato
              </Link>
              {user.token && (
                <Link className="new-nav-links" to="/dashboard">
                  Dashboard
                </Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
