import React from "react";
import { Link } from "react-router-dom";

import "./style.css";

export const LoginButton = ({ type }) => {
  return (
    <Link to="/entrar">
      <button
        className={type === "top-header" ? "top-header-login-btn" : "login-btn"}
      >
        ENTRAR
        {type === "top-header" ? (
          <i className="fas fa-lock top-header-lock-button"></i>
        ) : (
          ""
        )}
      </button>
    </Link>
  );
};
