import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../features/user";
import "./style.css";

const Logout = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    history.push("/");
  };

  return (
    <div className="center">
      <button className="logout-button" onClick={handleLogout}>
        Sair
      </button>
    </div>
  );
};

export default Logout;
