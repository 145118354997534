import React, { useState } from "react";
import { SliderData } from "../../utils/SliderData";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";

import "./style.css";

export const ImageSlider = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const length = slides.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const previousSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };
  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <div className="slider">
      <FaArrowAltCircleLeft className="left-arrow" onClick={previousSlide} />
      <FaArrowAltCircleRight className="right-arrow" onClick={nextSlide} />
      {SliderData.map((slide, index) => {
        return (
          <div className="row testa">
            <div
              className={
                index === current ? "slide active col-md-6" : "slide col-md-6"
              }
              key={index}
            >
              {index === current && (
                <img
                  src={slide.image}
                  alt="imagem do cartão"
                  className="slider-image"
                />
              )}
            </div>
            <div
              className={
                index === current ? "slideText active col-md-6" : "slideText col-md-6"
              }
              key={index}
            >
              {index === current && (
                <>
                  <p className="slider-title">{slide.title}</p>
                  <p className="slider-text">{slide.consultas}</p>
                  <p className="slider-text">{slide.atos_medicos}</p>
                  <p className="slider-text">{slide.farmacia}</p>
                  <p className="slider-text">{slide.inclusao}</p>
                  <p className="slider-text">Beneficiários: {slide.beneficiarios}</p>
                </>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
