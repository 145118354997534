import React, { useState } from "react";
import "./style.css";
import { VscBellDot } from "react-icons/vsc";
import picIncon from "../../images/tavatar.jpg";
import { NotificationModal } from "../NotificationModal";
import { useSelector } from "react-redux";

import { Avatar } from "../Avatar";

export const DashboardHeader = () => {
  const [isVisible, setIsVisible] = useState(false);
  const userName = useSelector((state) => state.user.value.userName);

  const showModal = () => {
    setIsVisible(!isVisible);
  };

  const notifications = [
    { user: "clinica1", message: "consultou" },
    { user: "clinica2", message: "consultou dados do alberto" },
    { user: "clinica3", message: "consultou dados do tadeu" },
  ];

  return (
    <div className="row dashboard-header-container">
      <div className="col-md-12 dashboard-header-content-container">
        <VscBellDot
          className="dashboard-header-content bell-icon"
          size={20}
          color={notifications.length >= 1 ? "#3dbf75" : "#000"}
          onClick={showModal}
          cursor="pointer"
        />
        <NotificationModal
          items={notifications}
          show={isVisible}
          handleClose={showModal}
          buttonText="Fechar"
        ></NotificationModal>
        <div className="dashboard-header-separator" />
        <Avatar img={picIncon} nome={userName} type="header" />
      </div>
    </div>
  );
};
