import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { login } from "../../features/user";
import { useHistory } from "react-router-dom";
import { Section } from "../../components/Section";
import { signIn, refreshSignIn } from "../../services/Conection.js";
import { Loading } from "../../components/Loading";
import "./style.css";

export const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    const result = await signIn(userName, password);
    setLoading(false);
    if (result.message) {
      setErrorMessage(result.message);
      setErrorVisible(true);
    }
    if (result.activo === 1 && result.auth) {
      dispatch(
        login({
          userName: result.userName,
          role: result.role,
          activo: result.activo,
          token: result.token,
          auth: result.auth,
        })
      );
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getDataFromLocalState();
    if (user.token && user.activo === 1) {
      history.push("/dashboard", { from: "entrar" });
    }

    if (user.activo === 0) {
      setErrorMessage("Usuário Desativado, contactar o Administrador");
    }
  });

  const getDataFromLocalState = () => {
    const localState = JSON.parse(localStorage.getItem("user"));
    if (localState!==null) {
      dispatch(
        login({
          userName: localState.userName,
          role: localState.role,
          activo: localState.activo,
          token: localState.token,
          auth: localState.auth,
        })
      );
    }
  };

  useEffect(() => {
    refreshSignIn();
    getDataFromLocalState();
  }, []);

  return (
    <div className="container">
      {loading ? (
        <Section type="login">
          <Loading size={80}></Loading>
        </Section>
      ) : (
        <Section type="login">
          <div className="row banner-login-container">
            <div className="col-md-6 login-background" />
            <div className="col-md-4">
              <div className="login-container">
                <div className="login-container-content">
                  <div>
                    <h6 className="login-title">
                      Seja bem vindo ao sistema do cartão família UPRA!
                    </h6>

                    <div className="form-group login-email">
                      <input
                        onChange={(text) => {
                          setUserName(text.target.value);
                        }}
                        type="text"
                        value={userName}
                        className="form-control login-input"
                        placeholder="Nome de usuário"
                      />
                    </div>

                    <div className="form-group login-password">
                      <input
                        onChange={(text) => {
                          setPassword(text.target.value);
                        }}
                        value={password}
                        type={isVisible ? "text" : "password"}
                        className="form-control login-input"
                        placeholder="Palavra passe"
                      />
                    </div>

                    <div className="row show-forget-container">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              onChange={() => setIsVisible(!isVisible)}
                              className="custom-control-input"
                              id="customCheck1"
                            />
                            <label
                              className="custom-control-label login-check"
                              htmlFor="customCheck1"
                            >
                              Mostrar senha
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <p className="forgot-password text-right">
                          {
                            <Link to="/entrar/resetar-pass">
                              Esqueci minha senha
                            </Link>
                          }
                        </p>
                      </div>
                    </div>

                    <div className="login-button-container">
                      <button
                        disabled={!userName}
                        className="btn  login-bttn"
                        onClick={handleLogin}
                      >
                        Entrar
                      </button>
                    </div>

                    {errorVisible ? (
                      <p className="login-error">{errorMessage}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
      )}
    </div>
  );
};
